import React from "react"
import {graphql, useStaticQuery} from 'gatsby'
import Hero from "../components/hero"
import HomeFeatures from "../components/HomeFeatures"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import AdComponent from "../components/AdComponent"

const heroStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(https://bibletolife.wpengine.com/wp-content/uploads/2021/10/sixteen-miles-out-PpJOb2ByPBY-unsplash.webp)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}
const HomePage = () => {

    const data = useStaticQuery(graphql`
        query PopularQuery {
            allWpTopic(filter: {popularity: {popular: {eq: true}}}) {
                nodes {
                    name
                    uri
                }
            }
            allWpAd(
                filter: {adPositions: {nodes: {elemMatch: {slug: {in: ["homepage-above-latest", "homepage-below-latest"]}}}}}
            ) {
                nodes {
                    id
                    title
                    adPositions {
                        nodes {
                            slug
                        }
                    }
                    adFields {
                        altText
                        endDate
                        externalLink
                        startDate
                    }
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    `)

    const filterAdsByPosition = (ads, position) => {
        return ads.nodes.filter(ad => {
            return ad.adPositions.nodes.some(node => node.slug === position)
        })
    }

    return (
        <Layout isIndex={true}>
            <Seo
                title="Home"
                description="Get to know the Bible."
            />
            <Hero style={heroStyle} data={data}/>

            <AdComponent ads={filterAdsByPosition(data.allWpAd, "homepage-above-latest")} position="homepage-above-latest" />

            <HomeFeatures/>

            <AdComponent ads={filterAdsByPosition(data.allWpAd, "homepage-below-latest")} position="homepage-below-latest" />

        </Layout>
    )
}

export default HomePage
