import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { faEye, faHashtag } from "@fortawesome/pro-regular-svg-icons"
import '../styles/hero.scss'

const Hero = (props) => {
    return (
        <section className="hero" style={props.style}>
            <h1>Get to Know the Bible</h1>
            <form className="hero__search" action="/search">
                <input name="q" type="text" placeholder="Search a topic or ask a question" />
                <span>
                    <FontAwesomeIcon icon={faSearch} size="lg" />
                </span>
            </form>
            <div className="hero__browse-links container">

                <div className="hero__browse-links__list">
                    <span><i><FontAwesomeIcon icon={faHashtag} size="lg" /></i> Popular Topics</span>
                    <ul>
                        {props.data.allWpTopic.nodes.map((term, index) =>
                            <li key={index}>
                              <a href={`/topics/${term.uri}`}>{term.name}</a>
                            </li>
                        )}
                    </ul>
                </div>
                <a href="/resources" className="btn btn--primary">
                    <i><FontAwesomeIcon icon={faEye} size="lg" /></i>Browse All Resources
                </a>
            </div>
        </section>
    )
}

export default Hero
